@font-face {
  font-family: "ClashDisplay-Variable";
  src:
    url("../src/assets/fonts/ClashDisplay-Variable.woff2") format("woff2"),
    url("../src/assets/fonts/ClashDisplay-Variable.woff") format("woff"),
    url("../src/assets/fonts/ClashDisplay-Variable.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Extralight";
  src:
    url("../src/assets/fonts/ClashDisplay-Extralight.woff2") format("woff2"),
    url("../src/assets/fonts/ClashDisplay-Extralight.woff") format("woff"),
    url("../src/assets/fonts/ClashDisplay-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Light";
  src:
    url("../src/assets/fonts/ClashDisplay-Light.woff2") format("woff2"),
    url("../src/assets/fonts/ClashDisplay-Light.woff") format("woff"),
    url("../src/assets/fonts/ClashDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Regular";
  src:
    url("../src/assets/fonts/ClashDisplay-Regular.woff2") format("woff2"),
    url("../src/assets/fonts/ClashDisplay-Regular.woff") format("woff"),
    url("../src/assets/fonts/ClashDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Medium";
  src:
    url("../src/assets/fonts/ClashDisplay-Medium.woff2") format("woff2"),
    url("../src/assets/fonts/ClashDisplay-Medium.woff") format("woff"),
    url("../src/assets/fonts/ClashDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Semibold";
  src:
    url("../src/assets/fonts/ClashDisplay-Semibold.woff2") format("woff2"),
    url("../src/assets/fonts/ClashDisplay-Semibold.woff") format("woff"),
    url("../src/assets/fonts/ClashDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Bold";
  src:
    url("../src/assets/fonts/ClashDisplay-Bold.woff2") format("woff2"),
    url("../src/assets/fonts/ClashDisplay-Bold.woff") format("woff"),
    url("../src/assets/fonts/ClashDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

:root {
  --primary-color: #3751fe;
  --text-primary: #00000099;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  background-color: #939ca3;
  border-radius: 10px;
}

.or {
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 5px;
}

.or span {
  text-align: center;
  flex: 0.2 0 auto;
  margin: 0;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.6);
}

.or::before,
.or::after {
  content: "";
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  flex: 1 0 auto;
  height: 0.5em;
  margin: 0;
}

.link {
  color: var(--primary-color);
  font-weight: bold;
  text-decoration: none;
  padding-left: 5px;
}

.app {
  height: 100%;
  width: 100%;
}

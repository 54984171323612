.sidebar {
  height: 100%;
  border-right: solid 1px #e7e7e7;
  min-width: 252px;
  max-width: 252px;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #f7f7f7;

  .username {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: black;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    font-size: 15px !important;
  }
  .sidebar-user-button {
    max-width: 22px;
    min-width: 22px;
    padding: 0px;
    justify-content: space-between;
    color: black;
    margin-right: 20px;
    margin-top: -2px;
    border-radius: 100%;
    text-transform: capitalize;
    font-weight: 400;
  }
  .add-tree {
    padding: 10px;
    border: 1px solid #d0d0d0;
    border-radius: 15px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    margin-bottom: 10px;
  }
  .add-tree:hover {
    background-color: #e7e7e7;
    border: 1px solid #f7f7f7;
  }
  .new-title {
    margin-bottom: -2px;
  }
  .card-detail {
    display: flex;
    align-items: center;
    width: calc(100% - 70px);
    font-size: 14px;
    height: auto;
    gap: 15px;
    cursor: pointer;
  }
  .sidebar-inner {
    position: relative;
    overflow: hidden auto;
    padding: 0 10px;
    height: calc(100% - 308px);
    max-height: 550px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #e7e7e7;
    /* -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      -webkit-appearance: none;
    } */
  }
  .event-sidebar-sub-title {
    margin-top: 40px;
    text-align: center;
  }
  .event-card {
    display: flex;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #f7f7f7;
    border-radius: 7px;
    gap: 10px;
    /* min-height: 60px; */
    flex: 1;
    display: flex;
    align-items: center;
    width: calc(100% - 22px);
    cursor: pointer;
  }
  .event-card:hover {
    background: white;
    /* border: 1px solid #a9a9a9; */
    .menuIcon {
      visibility: visible;
    }
  }

  .selected-card {
    border: 1px solid #3b75cd9c;
    background: white;
  }

  .selected-card:hover {
    border: 1px solid #3b75cd9c;
    background: #f1f1f1;
  }

  .optionsContainer {
    position: relative;
    display: inline-block;
    height: 16px;
  }

  .menuIcon {
    border-radius: 6px;
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 3px 5px;
    align-items: flex-start;
    visibility: hidden;
    cursor: pointer;
  }

  .menuIcon:hover {
    background: rgb(250, 250, 250);
    border: 1px solid #cecece;
  }

  .dropdown-content {
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    text-align: center;
    color: black;
    padding: 10px 12px;
  }

  .dropdown-content .dropdown-item:hover {
    background-color: #f0f0f0;
  }

  .feedback {
    text-decoration: none;
    display: flex;
    color: #585858;
    background-color: #eeeeee;
    cursor: pointer;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 8px;
    margin: auto;
    align-self: center;
  }
  .feedback:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .loadingCardContainer {
    background-color: #f3f3f3;
    border-radius: 5px;
    align-items: center;
    height: 82px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .event-type-title {
    padding: 5px;
    padding-left: 3px;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      color: #3b75cd;
    }
  }
  .sidebar-buttons-container {
    position: absolute;
    bottom: 0;
    padding: 10px 10px;
  }
  .sidebar-button {
    display: flex;
    align-items: center;
    height: 32px;
    width: 222px;
    padding-left: 10px;
    cursor: pointer;
    border-radius: 5px;
    color: #585858;
    font-size: 14px;
    text-decoration: none;
  }
  .sidebar-button img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  .sidebar-button:hover {
    background-color: #ececf1;
  }
}

.main-div {
  width: 100%;
  overflow: hidden;
  display: flex;
  background-color: white;
}

.left-panel {
  width: 100%;
  border-right: 1px solid #e1dede;
  flex-grow: 1;
  box-sizing: border-box;
  transition: margin 0.5s;
}

.right-panel {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 32%;
  height: 100%;
  z-index: 2;
  background-color: white;
  transition: left 0.5s;
  overflow: hidden;
}

.app-sidebar-resizer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 4px;
  justify-self: flex-end;
  cursor: col-resize;
  resize: horizontal;
}

.app-sidebar-resizer:hover {
  width: 1px;
}

.create-chart-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.add-chart-icon {
  cursor: pointer;
}

.dashboard {
  height: calc(100% - 60px);
  overflow: auto;
}

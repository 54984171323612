.header-row {
  display: flex;
  align-items: center;
  padding: 10px;
}
.header-row {
  background-color: #f8fafb;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.kpi-row {
  margin-top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px;
  &:hover {
    background-color: #f8fafb;
  }
}
.delete-icon {
  cursor: pointer;
  fill: #868c98;
  &:hover {
    fill: red;
  }
}
.extraInfo {
  margin-top: 30px;
  width: 100%;
  display: flex;
  gap: 2%;
}
.extraInfoBox {
  width: 30%;
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 15px;
  min-height: 100px;
}
.extraInfoBoxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "ClashDisplay-Medium";
  margin-bottom: 10px;
}
.header-cell {
  font-size: 14px;
  color: #868c98;
  padding-left: 20px;
}
.kpi-cell {
  padding-left: 10px;
  font-size: 14px;
  color: #000;
}
.status-box {
  padding: 3px 5px;
  font-size: 13px;
  font-family: "ClashDisplay-Medium";
  width: fit-content;
  border-radius: 5px;
  margin-left: -10px;
}
.header-cell:nth-child(1),
.kpi-cell:nth-child(1) {
  width: 20%;
}
.header-cell:nth-child(2),
.kpi-cell:nth-child(2) {
  width: 30%;
}
.header-cell:nth-child(3),
.kpi-cell:nth-child(3) {
  width: 30%;
}
.header-cell:nth-child(4),
.kpi-cell:nth-child(4) {
  width: 15%;
}
.header-cell:nth-child(5),
.kpi-cell:nth-child(5) {
  width: 5%;
}

.progress-container {
  width: 250px;
  max-width: 50vw;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "ClashDisplay-Medium";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
